<template>
    <span></span>
</template>

<script>
    import {GetPartnerAuthUrl} from '@/request/api';
    import {mapState} from 'vuex';//mapState读取store中state中的变量
    export default {
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
            getLoginUrl(loginType) {
                /*if (this.logined) {
                    this.$message.success("已登入成功");
                    this.$router.push('/');
                    return;
                }*/
                GetPartnerAuthUrl(loginType).then(res => {
                    if (res.code == 200) {//取得line授权网址并跳转
                        window.location.replace(res.data);
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(e => {
                    this.$message.error(e);
                    console.log(e);
                });
            }
        },
        mounted() {
            if (this.$route.params.type) {
                this.getLoginUrl(this.$route.params.type);
            }
        },
        computed: {
            ...mapState(['logined'])
        },
    }
</script>
<style lang="less" scoped>

</style>
